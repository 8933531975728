import ajax from './index';


export const getJobList = (page_num, page_size) =>
	ajax('/api/auth/feedback/list', { page_num, page_size });

// 线上接口
export const getCountryList = (query) =>
	ajax('http://ip-api.com/batch?lang=zh-CN&fields=country,city,query', query, 'post');

// 本地接口
// export const getCountryList = (query) =>
// 	ajax('/batch?lang=zh-CN&fields=country,city,query', query, 'post');
/*
 *   5. 设置是否轮播图
 * */
export const setFocusJob = (id, is_focus) =>
	ajax('/api/auth/feedback/set_focus_job', { id, is_focus });

export const setFocusJobAll = (id) =>
	ajax('/api/auth/feedback/set_focus_job_all', { id });
/*
 *   6. 删除直播课程
 * */
export const deleteJob = (id) => ajax('/api/auth/feedback/delete_job', { id });
/*
 *   7. 修改职场人生
 * */
export const editJob = (
	token,
	id
) =>
	ajax(
		'/api/auth/feedback/edit',
		{
			token,
			id
		},
		'post'
	);
