import React from 'react';
import {
	Card,
	Button,
	Table,
	Switch,
	Divider,
	Modal,
	message,
	BackTop,
	notification,
} from 'antd';
import { deleteJob, getJobList, setFocusJob,getCountryList,setFocusJobAll} from '../../../api/feedbackApi';
import moment from 'moment';
import {getNotRead} from "../../../api/homeApi";
import ExportJsonExcel from "js-export-excel";

export default class FeedbackList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			feedbackList: [],
			loading: true,
			totalSize: 0,
			pageSize: 100,
		};

	}
	componentWillUnmount() {
		this.setState = () => false;
	}
	componentDidMount() {
		if (this.props.location.state) {
			const page_num = this.props.location.state.page_num;
			this.setState({
				pageNum: page_num,
			});
			this._loadData(page_num, this.state.pageSize);
		}else {
			this._loadData();
		}

	}


	_loadData = (page_num = 1, page_size = 100) => {
		const parseMsgAndAddToObject = (obj) => {
			const msgPairs = obj.msg.split('&&');
			msgPairs.forEach(pair => {
				const [key, value] = pair.split('=');
				// 去除键和值两侧的空白字符
				// const trimmedKey = key.trim();
				// const trimmedValue = value.trim();
				// 如果键不为空，则添加到对象中
				if (key) {
					obj[key] = value || null; // 如果值为空字符串，则赋值为null
				}
			});
			// 删除原始的msg字段，如果不需要保留的话
			delete obj.msg;
			return obj;
		};
		getJobList(page_num, page_size)
			.then((result) => {
				if (result && result.status === 1) {
					message.success(result.msg);
					this.setState({
						loading: false,
						pageNum: page_num,
						totalSize: result.data.job_count,
					});
					let feedbackList = result.data.job_list;
					let ips = feedbackList.map(feed => feed.ip);
					getCountryList(ips).then((result) => {
						for (let i = 0; i < result.length; i++) {
							for (let j = 0; j < feedbackList.length; j++) {
								if (feedbackList[j].ip === result[i].query) {
									feedbackList[j].country = result[i].country;
									feedbackList[j].city = result[i].city;
								}
							}
						}
						feedbackList.forEach(feedback => {
							// feedback.arrayMsg = stringToObject(feedback.msg);
							 feedback.addtime = moment(feedback.addtime).format('YYYY-MM-DD HH:mm:ss');
							 parseMsgAndAddToObject(feedback);

						});
						this.setState({
							feedbackList: feedbackList,
							pageSize: page_size,
						});
					}).catch((error)=>{
						console.log(error);
					})

					// this.props.history.push({
					// 	pathname: '/feedback',
					// });
				}
			})
			.catch(() => {
				message.error('获取列表失败');
			});
	};

	// 列的配置信息
	 columns = [
		{ title: 'ID', dataIndex: 'id', width: 80, align: 'center' },
		{ title: 'IP', dataIndex: 'ip', align: 'center' },
		{ title: '国家', dataIndex: 'country', align: 'center' },
		// { title: '城市', dataIndex: 'city', align: 'center' },
		// { title: '反馈者身份', dataIndex: 'customerid',width: 120,
		// 	render: (text) => {
		// 		let customer = text === 0 ? '管理员' : '游客';
		// 		return (
		// 			customer
		// 		);
        //       },
		// 	align: 'center' },
		{
			title: '已读',
			dataIndex: 'readok',
			width: 100,
			render: (text, record) => {
				return (
					<Switch
						checkedChildren={'是'}
						unCheckedChildren={'否'}
						defaultChecked={text === 1}
						// disabled={!record.focus_img}
						onChange={(checked) => {
							setFocusJob(record.id, checked ? 1 : 0).then(
								(result) => {
									if (result && result.status === 1) {
										notification['success']({
											message: `留言:${record.fdtitle}`,
											style: {
												marginTop: '50px',
											},
											description: `${
												checked ? '设置为' : '取消'
											}已读`,
										});
										record.readok = checked ? 1 : 0;
										getNotRead().then((result) => {
											if (result && result.status === 1) {
												window.localStorage.setItem('msj_count', result.data.msg_count);
												this.props.history.push({
													pathname: '/feedback',
												});
											}
										}).catch( (error) => {
											console.log(error);
										})
									}
								}
							);
						}}
					/>
				);
			},
			align: 'center',
		},
		{ title: '提交时间', dataIndex: 'addtime',align: 'center'},
		{ title: '来源页面地址', dataIndex: 'fromurl',ellipsis: true,width: 300,align: 'center',},
		 // { title: '客户端', dataIndex: 'useinfo',ellipsis:true, align: 'center' },
		 { title: '留言内容', dataIndex: '122',ellipsis: true, align: 'center' },
		 { title: '电话', dataIndex: '117', align: 'center' },
		 { title: '邮箱', dataIndex: '116', align: 'center' },
		 { title: '姓名', dataIndex: '115', align: 'center' },

		{
			title: '操作',
			align: 'center',
			width: 180,
			render: (text, record) => {
				return (
					<div>
						<Button
							onClick={() => {
								this.props.history.push({
									pathname: '/feedback/add-edit',
									state: {
										job: record,
										current:this.state.pageNum
									},
								});
								setFocusJob(record.id, 1).then(
									(result) => {
										if (result && result.status === 1) {
											console.log('设置为已读');
										}
									}
								);
							}}
						>
							查看
						</Button>
						<Divider type='vertical' />
						<Button
							onClick={() => {
								Modal.confirm({
									title: '确认删除吗',
									content:
										'删除此留言无法被找回',
									okText: '确认',
									cancelText: '取消',
									onOk: () => {
										deleteJob(record.id)
											.then((result) => {
												if (
													result &&
													result.status === 1
												) {
													message.success(result.msg);
													this._loadData(
														this.state.pageNum,
														this.state.pageSize
													);
												} else {
													message.error('删除失败');
												}
											})
											.catch(() => {
												message.error('删除失败');
											});
									},
								});
							}}
						>
							删除
						</Button>
					</div>
				);
			},
		},
	];


	render() {
		const handelClickExportFile = ((mockData) => {
			let sheetFilter = ['ip','country', 'addtime', 'fromurl','122','117','116','115'];
			let option = {
				fileName: '留言表格-'+moment().format('YYYY-MM-DD'), // 导出默认文件名
				datas: [
					{
						sheetData: mockData, // 保存至表格的数据
						sheetName: '', // excel表格sheet默认名称
						sheetFilter: sheetFilter,
						sheetHeader: ['IP', '国家','提交时间', '来源链接','留言内容','电话','邮箱','姓名'], // 表格-表头
						columnWidths: ['6', '6', '12', '30', '20','10','10','10'], // 单元格宽度
					},
				]
			}
			let toExcel = new ExportJsonExcel(option) // 创建新的模板
			toExcel.saveExcel() // 保存
		})
		const { selectedRowKeys } = this.state;
		const onSelectChange = (newSelectedRowKeys) => {
			// console.log('selectedRowKeys changed: ', newSelectedRowKeys);
			this.setState({selectedRowKeys:newSelectedRowKeys});
		};
		const rowSelection = {
			selectedRowKeys,
			onChange: onSelectChange,
		};
		const start = () => {
			setFocusJobAll(this.state.selectedRowKeys).then((result) => {
				if (result && result.status === 1) {
					message.success(result.msg);
					this._loadData(this.state.pageNum, this.state.pageSize);
					// window.location.reload();
					this.setState({
						selectedRowKeys: [],
					})
				}
			})
		};
		const style = {
			height: 40,
			width: 40,
			lineHeight: '40px',
			borderRadius: 4,
			backgroundColor: '#1088e9',
			color: '#fff',
			textAlign: 'center',
			fontSize: 14,
			position:'absolute',
			right:'-70px'
		};
		let ReturnBtn = (
			<Button type={'primary'} onClick={() => handelClickExportFile(this.state.feedbackList)}>导出表格</Button>
		)
		return (
			<div>
				<Card title={'留言列表'} extra={ReturnBtn}>

					<Table
						rowKey={'id'}
						loading={this.state.loading}
						bordered
						rowSelection={rowSelection}
						columns={this.columns}
						dataSource={this.state.feedbackList}
						pagination={{
							total: this.state.totalSize,
							pageSize: this.state.pageSize,
							current: this.state.pageNum,
							onChange: (pageNum, pageSize) => {
								this._loadData(pageNum, pageSize);
							},
						}}
					></Table>
					<Button type="primary" disabled={!this.state.selectedRowKeys} style={{ position: 'absolute', bottom: 35}} onClick={start}>
						批量设置已读
					</Button>
					<BackTop>
						<div style={style}>UP</div>
					</BackTop>

				</Card>
			</div>
		);
	}
}
