import React from 'react';
import {
	Card,
	Button,
	message,
	Table,
} from 'antd';
import {
	editJob,
} from '../../../api/feedbackApi';

import { getUser } from '../../../api/adminApi';
import { getObj } from '../../../tools/cache-tool';

export default class AddEditFeedback extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// form数据
			id: '',
			loading: true,
		};
		this.lifejobFormRef = React.createRef();
		this.lifejob_ref = React.createRef();
	}

	componentDidMount() {
		// 0.刷新页面处理
		if (getObj('lifejob_tag') === 'edit' && !this.props.location.state) {
			this.props.history.goBack();
		}
		// 1. 获取上一个界面传递的数据
		if (this.props.location.state) {
			// const jobItem = this.props.location.state.job;
			// if (jobItem) {
			// 	this.lifejobFormRef.current.setFieldsValue(jobItem);
			// 	this.setState({
			// 		id: jobItem.id,
			// 		job_content: jobItem.job_content,
			// 		imageUrl: jobItem.job_img,
			// 		focusImgUrl: jobItem.focus_img,
			// 	});
			// }
			editJob(getUser().token,this.props.location.state.job.id)
				.then((result) => {
					if (result && result.status === 1) {
						message.success(result.msg);
						this.setState({
							loading : false,
							job_detail: result.data.job_detail,
						});
					}
				})
				.catch(() => {
					message.error('获取详情失败');
				});

		}

	}

	// 销毁组件前清空state内存
	componentWillUnmount() {
		this.setState = (state, callback) => {
			return false;
		};
	}
	columns = [

		{ dataIndex: 'paraid', width: 50,
			render: (text) => {
			if(text === 115) {
				return (
					'Your Name'
				);
			}else if (text === 116) {
				return (
					'Your E-mail'
				);
			}else if (text === 117) {
				return (
					'Your Tel'
				);
			}else if (text === 118) {
				return (
					'message type'
				);
			}else if (text === 119) {
				return (
					'message name'
				);
			}else if (text === 132) {
				return (
					'yuyan'
				);
			}else if (text === 121) {
				return (
					'Your country'
				);
			}else if (text === 122) {
				return (
					'Message'
				);
			}else if (text === 133) {
				return (
					'Method'
				);
			}else if (text === 134) {
				return (
					'Number'
				);
			}else if (text === 135) {
				return (
					'Capacity'
				);
			}else if (text === 136) {
				return (
					'Materials'
				);
			}else if (text === 137) {
				return (
					'Mensaje'
				);
			}else{
				return (
					text
				);
			}

			},
			align: 'left' },
		{ dataIndex: 'info', width: 150, align: 'left' },



	];

	render() {
	let ReturnBtn = (
				<Button
					type={'primary'}
					onClick={() => {
						this.props.history.push({
							pathname: '/feedback',
							state: {
								page_num: this.props.location.state.current,
							}
						});

					}}
				>
					返回列表
				</Button>
			);
		return (
			<div>
				<Card title={'留言详情'} extra={ReturnBtn}>
					<Table
						bordered
						loading={this.state.loading}
						showHeader={false}
						pagination={false}
						rowKey={'id'}
						columns={this.columns}
						dataSource={this.state.job_detail}
					></Table>
				</Card>
			</div>
		);
	}

}
