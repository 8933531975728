import React from 'react';
import { Switch, Route } from 'react-router-dom';

import FeedbackList from './pages/feedback-list';
import AddEditFeedback from './pages/add-edit-feedback';
import NotFound from '../notFound/not-found';

export default class Feedback extends React.Component {
	render() {
		return (
			<Switch>
				<Route path={'/feedback/add-edit'} component={AddEditFeedback} />
				<Route path={'/feedback'} component={FeedbackList} />
				<Route component={NotFound} />
			</Switch>
		);
	}
}
