import React from 'react';
import {Button, Input, message,Modal, Table} from 'antd';

import '../home.css';
import {getSearchCate, getSearchName} from "../../../api/homeApi";
import { SearchOutlined} from "@ant-design/icons";
import moment from "moment";
const {confirm} = Modal;

export default class HomeList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataList: [],
			loading: false,
			searchString: '',
			cateDescription: '',
		};
	}
	render() {
		const onPressEnter = () => {
			this.setState({
				loading: true,
				searchString: '',
			});
			getSearchName(this.state.searchString).then((result) => {
				if (result && result.status === 1) {
					message.success(result.msg);
					this.setState({
						loading: false,
						dataList: result.data,
					});
				}
			}).catch(() => {
				message.error('获取列表失败');
			});
		}
		const columns = [
			{
				title: '栏目',
				dataIndex: 'listid',
			},
			{
				title: '字段值',
				dataIndex: 'paraid',
				render: (text) => {
					if(text === 115) {
						return (
							'Name'
						);
					}else if (text === 116) {
						return (
							'E-mail'
						);
					}else {
						return (
							'Message'
						);
					}
				},
			},
			{
				title: '信息',
				dataIndex: 'info',
			},
			{
				title: '语言',
				dataIndex: 'lang',
			},
			{
				title: '操作',
				render: (text, record) => {
					return (
						<div>
							<Button
								onClick={() => {
									getSearchCate(record.listid).then(
										(result) => {
											if (result && result.status === 1) {
												this.setState({
													cateDescription: result.data[0],
												})

												confirm({
													title: '栏目信息',
													content:(
														<>
															标题:  {this.state.cateDescription.fdtitle}
															<br />
															留言时间:  {moment(this.state.cateDescription.addtime).format('YYYY-MM-DD hh:mm:ss')}
															<br />
															留言地址:  {this.state.cateDescription.fromurl}
															<br />
															ip地址:  {this.state.cateDescription.ip}
														</>
													),
													okText: '确定',


												});
											}
										}
									);
								}}
							>
								查看栏目信息
							</Button>

						</div>
					);
				},
			},
		];
		return (
			<div className='home'>
				<div className='home-top'>
				<Input prefix={ <SearchOutlined /> }
				value={this.state.searchString}
					   onChange={(e) => this.setState({searchString: e.target.value})}
					   placeholder="请输入搜索的姓名或邮箱地址并按回车提交" onPressEnter={onPressEnter}  />
				</div>
				<div className='home-content'>
				<Table
					rowKey={'id'}
					loading={this.state.loading}
					bordered
					columns={columns}
					dataSource={this.state.dataList}
				/>
				</div>
			</div>
		);
	}
}
